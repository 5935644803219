import React from "react";
import styled from "styled-components";
import { getFlex, linkRouge, palette } from "../../styles/styles";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Link } from "gatsby";

const Prefooter = (props) => {
  const { className, nameSpace, isFromExec } = props;
  const { t } = useTranslation(nameSpace);

  return (
    <section className={className}>
      <h2>{t("prefooterTitle")}</h2>
      <div
        className="buttons"
        style={{ justifyContent: isFromExec ? "space-between" : "center" }}
      >
        {isFromExec ? (
          <Link to="/contact" className="link">
            {t("prefooterButton")}
          </Link>
        ) : (
          <a
            href="https://ats.lapieza.io/authorize-me"
            target="_blank"
            rel="noreferrer"
            className="link"
          >
            {t("prefooterButton")}
          </a>
        )}
        {isFromExec ? <p>{t("or")}</p> : null}
        {isFromExec ? (
          <a
            href="https://ats.lapieza.io/authorize-me"
            target="_blank"
            rel="noreferrer"
            className="link"
          >
            {t("getStarted")}
          </a>
        ) : null}
      </div>
    </section>
  );
};

export default styled(Prefooter)`
  padding: 8% 10%;
  height: fit-content;
  background-color: ${palette.blue};
  display: grid;
  grid-template-rows: auto;
  grid-row-gap: 6rem;
  align-items: center;
  justify-items: center;
  @media (max-width: 600px) {
    padding: 10% 8%;
  }
  h2 {
    color: ${palette.whiteWhite};
    font-size: 4.8rem;
    width: 60%;
    text-align: center;
    @media (max-width: 1024px) {
      width: 70%;
    }
    @media (max-width: 768px) {
      width: 80%;
    }
    @media (max-width: 600px) {
      width: 90%;
    }
  }
  .buttons {
    ${getFlex("row", "ct", "ct")};
    width: 50%;
    @media (max-width: 1100px) {
      width: 55%;
    }
    @media (max-width: 900px) {
      width: 60%;
    }
    @media (max-width: 768px) {
      flex-direction: column;
      row-gap: 1rem;
    }
    .link {
      ${linkRouge};
    }
    p {
      color: ${palette.whiteWhite};
      font-size: 2rem;
    }
  }
`;
