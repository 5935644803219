import React from "react";
import styled from "styled-components";
import { getFlex, palette } from "../../styles/styles";
import { useTranslation } from "gatsby-plugin-react-i18next";
import stain0 from "../../images/stain/Stain1.png";
import stain1 from "../../images/stain/Stain2.png";
import stain2 from "../../images/stain/Stain3.png";

const Reasons = (props) => {
  const { className } = props;
  const { t } = useTranslation(["hiringSuite"]);
  const data = [
    {
      title: "70%",
      desc: "percentageFirstDesc",
      img: "Stain1.png",
    },
    { title: "3x", desc: "percentageSecondDesc", img: "Stain2.png" },
    {
      title: "90%",
      desc: "percentageThirdDesc",
      img: "Stain3.png",
    },
  ];

  return (
    <section className={className}>
      <h2>{t("reasonsTitle")}</h2>
      <p>{t("reasonsDesc")}</p>
      <div className="items">
        {data.map((item, index) => (
          <div className="item" id={`item${index}`}>
            <div className={`h4-${index} percentageStain`}>
              <h4 className={`h4-${index}`}>{t(item.title)}</h4>
            </div>
            <p>{t(item.desc)}</p>
          </div>
        ))}
      </div>
      <hr className="separator" />
    </section>
  );
};

export default styled(Reasons)`
  padding: 8% 10% 0 10%;
  height: fit-content;
  display: grid;
  grid-template-rows: auto;
  justify-items: center;
  align-items: center;
  grid-row-gap: 4rem;
  background-color: ${palette.blue};
  color: ${palette.whiteWhite};
  @media (max-width: 768px) {
    padding: 10% 8%;
    h2{
      width: 80%!important;
    }
    p{
      width: 90%!important;
    }
  }
  h2 {
    font-size: 4.8rem;
    text-align: center;
    color: ${palette.whiteWhite};
    width: 60%;
    @media(max-width: 768px){
      width: 80%;
    }
  }
  p {
    font-size: 2rem;
    text-align: center;
    width: 65%;
    line-height: 3.2rem;
  }
  .items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    justify-items: center;
    height: fit-content;
    width: 80%;
    @media (max-width: 900px) {
      display: grid;
      grid-template-rows: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
      justify-items: center;
    }
    @media (max-width: 768px) {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      justify-items: center;
      align-items: center;
      grid-row-gap: 2rem;
    }
    .item {
      ${getFlex("column", "st", "ct")};
      row-gap: 2rem;
      height: 100%;
      width: 100%;
      .percentageStain {
        width: 100%;
        ${getFlex("row", "ct", "ed")};
        h4 {
          color: ${palette.gold};
          font-size: 5rem;
          width: 60%;
          text-align: center;
          height: 100%;
        }
        .h4-0 {
          background-image: url(${stain0});
          background-repeat: no-repeat;
          background-attachment: local;
          background-position: 90% 0;
          background-size: 8rem;
          @media (max-width: 900px) {
            background-position: 90% 0;
          }
          @media (max-width: 768px) {
            background-position: 70% 0;
          }
        }
        .h4-1 {
          background-image: url(${stain1});
          background-repeat: no-repeat;
          background-attachment: local;
          background-position: 98% 0;
          background-size: 8rem;
          @media (max-width: 900px) {
            background-position: 90% 0;
          }
          @media (max-width: 768px) {
            background-position: 70% 0;
          }
        }
        .h4-2 {
          background-image: url(${stain2});
          background-repeat: no-repeat;
          background-attachment: local;
          background-position: 90% 0;
          background-size: 5rem;
          @media (max-width: 900px) {
            background-position: 60% 0;
          }
          @media (max-width: 768px) {
            background-position: 70% 0;
          }
        }
      }
      p {
        font-size: 2rem;
        text-align: center;
        width: 70%;
        font-family: SofiaProLightAz, sans-serif;
        @media (max-width: 900px) {
          width: 60%;
        }
      }
    }
    #item2 {
      @media (max-width: 900px) {
        grid-column: 1/-1;
      }
    }
  }
  .separator {
    height: 2px;
    color: ${palette.whiteWhite};
    width: 75%;
  }
`;
