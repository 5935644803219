import React from "react";
import Layout from "../comps/reusable/Layout";
import HeroSection from "../comps/reusable/HeroSection";
import styled from "styled-components";
import Partners from "../comps/reusable/Partners";
import Info from "../comps/hiring-suite/Info";
import Slides from "../comps/reusable/Slides";
import Reasons from "../comps/hiring-suite/Reasons";
import Quotes from "../comps/home/Quotes";
import Integrations from "../comps/hiring-suite/Integrations";
import Prefooter from "../comps/reusable/Prefooter";
import { graphql } from "gatsby";
import Carousel1 from "../comps/reusable/Carousel1";

const Ats = () => {
  return (
    <Layout>
      <HeroSection
        data={{
          imageName: "heroHiring.png",
          hasButtons: true,
        }}
        nameSpace="hiringSuite"
      />
      <Partners
        logos={[
          "partner1.png",
          "partner5.png",
          "partner4.png",
          "partner2.png",
          "partner3.png",
        ]}
      />
      <Info />
      <Slides
        data={[
          {
            red: "hsRed1",
            title: "hsTitle1",
            desc: "hsDesc1",
            button: "hsButton1",
            image: "slideHiring.png",
          },
          {
            red: "hsRed2",
            title: "hsTitle2",
            desc: "hsDesc2",
            button: "hsButton2",
            image: "secondSlideATS.png",
          },
        ]}
        fromHiring
      />
      <Carousel1
        info={[
          {
            title: "carouselFirstTitle",
            desc: "carouselFirstDesc",
            img: "carouDeux.png",
          },
          {
            title: "carouselSecondTitle",
            desc: "carouselSecondDesc",
            img: "carou1.png",
          },
          {
            title: "carouselThirdTitle",
            desc: "carouselThirdDesc",
            img: "carouTrois.png",
          },
        ]}
        nameSpace="hiringSuite"
      />
      <Reasons />
      <Quotes
        data={[
          {
            quote: "quotesFirst",
            author: "authorFirst",
          },
          {
            quote: "quotesSecond",
            author: "authorSecond",
          },
          {
            quote: "quotesThird",
            author: "authorThird",
          },
          {
            quote: "quotesFourth",
            author: "authorFourth",
          },
        ]}
        nameSpace="home"
      />
      <Integrations />
      <Prefooter
        nameSpace="hiringSuite"
      />
    </Layout>
  );
};

export default styled(Ats)``;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
