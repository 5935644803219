import React from "react";
import styled from "styled-components";
import Image from "../../ImageQuerys/LogoImages";
import { getFlex } from "../../styles/styles";
import { useTranslation } from "gatsby-plugin-react-i18next";

const Integrations = (props) => {
  const { className } = props;
  const { t } = useTranslation("hiringSuite");

  return (
    <section className={className}>
      <h2>{t("integrationsTitle")}</h2>
      <p>{t("integrationsDesc")}</p>
      <div className="allLogos">
        <div className="firstLine">
          <Image className="logo" imageName="logo1.png" />
          <Image className="logo" imageName="logo2.png" />
          <Image className="logo" imageName="logo3.png" />
          <Image className="logo" imageName="logo4.png" />
          <Image className="logo" imageName="logo5.png" />
          <Image className="logo" imageName="logo6.png" />
        </div>
        <div className="secondLine">
          <Image className="logo" imageName="logo7.png" />
          <Image className="logo" imageName="logo8.png" />
          <Image className="logo" imageName="logo9.png" />
          <Image className="logo" imageName="logo10.png" />
        </div>
        <div className="thirdLine">
          <Image className="logo" imageName="logo11.png" />
          <Image className="logo" imageName="logo12.png" />
        </div>
        <div className="fourthLine">
          <Image className="logo" imageName="logo13.png" />
          <Image className="logo" imageName="logo14.png" />
        </div>
      </div>
    </section>
  );
};

export default styled(Integrations)`
  padding: 8% 10%;
  height: max-content;
  display: grid;
  grid-template-rows: repeat(4, max-content);
  grid-row-gap: 4rem;
  justify-content: center;
  h2 {
    font-size: 4.8rem;
    width: 85%;
    text-align: center;
    margin: auto;
    @media(max-width: 1024px){
      width: 80%;
    }
    @media(max-width: 768px){
      width: 90%;
    }
    @media(max-width: 600px){
      width: 95%;
      padding: 10% 8%;
    }
  }
  p {
    font-size: 2rem;
    font-family: SofiaProLightAz, sans-serif;
    width: 60%;
    text-align: center;
    margin: auto;
    @media(max-width: 1024px){
      width: 70%;
    }
    @media(max-width: 768px){
      width: 80%;
    }
    @media(max-width: 600px){
      width: 90%;
    }
  }
  .allLogos {
    ${getFlex("column", "sb", "ct")};
    margin-top: 4rem;
    row-gap: 2rem;
    .firstLine {
      width: 85%;
      margin: auto;
      ${getFlex("row", "sb", "ct")};
    }

    .secondLine {
      width: 54%;
      margin: auto;
      ${getFlex("row", "sb", "ct")};
    }

    .thirdLine {
      width: 72%;
      margin: -6rem auto 0 auto;
      ${getFlex("row", "sb", "ct")};
      @media(max-width: 768px){
        margin: -3rem auto 0 auto;
      }
    }
    .fourthLine {
      width: 90%;
      margin: -6rem auto 0 auto;
      ${getFlex("row", "sb", "ct")};
      @media(max-width: 768px){
        margin: -3rem auto 0 auto;
      }
    }
    .logo {
      width: 10rem;
    }
  }
`;
