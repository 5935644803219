import React from "react";
import styled from "styled-components";
import { getFlex } from "../../styles/styles";
import Image from "../../ImageQuerys/InfoImages";
import { useTranslation } from "gatsby-plugin-react-i18next";

const Info = (props) => {
  const { className } = props;
  const { t } = useTranslation(["hiringSuite"]);

  const data = [
    {
      img: "Info1.png",
      title: "infoFirstTitle",
      desc: "infoFirstDesc",
    },
    {
      img: "Info2.png",
      title: "infoSecondTitle",
      desc: "infoSecondDesc",
    },
    {
      img: "Info3.png",
      title: "infoThirdTitle",
      desc: "infoThirdDesc",
    },
  ];

  return (
    <section className={className}>
      <div className="intro">
        <h2>{t("infoTitle")}</h2>
        <p>{t("infoDesc")}</p>
      </div>
      <div className="items">
        {data.map((item) => (
          <div className="item">
            <Image className="icon" imageName={item.img} />
            <h5>{t(item.title)}</h5>
            <p>{t(item.desc)}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default styled(Info)`
  padding: 8% 16%;
  height: fit-content;
  ${getFlex("column", "sb", "ct")};
  row-gap: 4rem;
  .intro {
    ${getFlex("column", "sb", "ct")};
    h2 {
      font-size: 4.8rem;
      text-align: center;
      margin-bottom: 2rem;
    }
    p {
      text-align: center;
      width: 75%;
      font-size: 2rem;
      font-family: SofiaProLightAz, sans-serif;
    }
  }
  .items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 5rem;
    grid-row-gap: 4rem;
    @media(max-width: 768px){
      grid-template-columns: 1fr;
      grid-template-rows: repeat(3,1fr);
    }
    .item {
      display: grid;
      grid-template-rows: 2fr 1fr 3fr;
      justify-items: center;
      align-content: center;
      @media(max-width: 1024px){
        grid-template-rows: auto;
        grid-row-gap: 1rem;
      }
      .icon{
        width: 35%;
        @media(max-width: 1024px){
          width: 30%;
        }
        @media(max-width: 768px){
          width: 25%;
        }
        @media(max-width: 600px){
          width: 20%;
        }
        img{
          object-fit: contain!important;
        }
      }
      h5 {
        font-size: 2rem;
        text-align: start;
        margin-bottom: 1rem;
        @media(max-width: 768px){
          text-align: center;
        }
      }
      p {
        font-family: SofiaProLightAz, sans-serif;
        font-size: 1.6rem;
        line-height: 3.2rem;
        @media(max-width: 768px){
          width: 80%;
          text-align: center;
        }
      }
    }
  }
`;
